import React from 'react'
import axios from 'axios'
import Layout from '../components/layout'

class Quote extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      successMessage: '',
      errorMessage: '',
      formFields: {
        name: '',
        email: '',
        phone: '',
        street: '',
        suburb: '',
        city: '',
        dateFrom: '',
        residentialCommercial: 'Residential',
        jobCategory: 'Plumbing',
        message: '',
      },
    }
  }

  render() {
    return (
      <Layout>
        <div className="box">
          <h2 className="stripe">Get a Quote</h2>
          <p>
            At Ace High Plumbing we understand how hectic life can be, and sometimes you just don't want to have a to
            pick
            up
            the phone. This forms for you! Just fill her out, and we'll be in touch with a quote in the next few days.
          </p>

          <form onSubmit={this.onFormSubmit}>
            <dl>
              <dt><label htmlFor="name">Name *</label></dt>
              <dd><input type="text" name="name" id="name" value={this.state.formFields.name}
                         onChange={this.inputChangeHandler}/></dd>

              <dt><label htmlFor="email">Email *</label></dt>
              <dd><input type="text" name="email" id="email" value={this.state.formFields.email}
                         onChange={this.inputChangeHandler}/></dd>

              <dt><label htmlFor="phone">Phone *</label></dt>
              <dd><input type="text" name="phone" id="phone" value={this.state.formFields.phone}
                         onChange={this.inputChangeHandler}/></dd>

              <dt><label htmlFor="street">Street *</label></dt>
              <dd><input type="text" name="street" id="street" value={this.state.formFields.street}
                         onChange={this.inputChangeHandler}/></dd>

              <dt><label htmlFor="suburb">Suburb</label></dt>
              <dd><input type="text" name="suburb" id="suburb" value={this.state.formFields.suburb}
                         onChange={this.inputChangeHandler}/></dd>

              <dt><label htmlFor="city">City *</label></dt>
              <dd><input type="text" name="city" id="city" value={this.state.formFields.city}
                         onChange={this.inputChangeHandler}/></dd>

              <dt><label htmlFor="dateFrom">Desired Start </label></dt>
              <dd><input type="text" className="datepicker hasDatepicker" id="dateFrom" name="dateFrom"
                         value={this.state.formFields.dateFrom} onChange={this.inputChangeHandler}/></dd>

              <dt>Type</dt>
              <dd>
                <label htmlFor="residential"><input type="radio" name="residentialCommercial" id="residential"
                                                    value="Residential"
                                                    checked={this.state.formFields.residentialCommercial === 'Residential'}
                                                    onChange={this.inputChangeHandler}/> Residential</label>
                <label htmlFor="commercial"><input type="radio" name="residentialCommercial" id="commercial"
                                                   value="Commercial"
                                                   checked={this.state.formFields.residentialCommercial === 'Commercial'}
                                                   onChange={this.inputChangeHandler}/> Commercial</label>
              </dd>

              <dt><label htmlFor="jobCategory">Job Area </label></dt>
              <dd>
                <select name="jobCategory" id="jobCategory" value={this.state.formFields.jobCategory}
                        onChange={this.inputChangeHandler}>
                  <option value="Plumbing">Plumbing</option>
                  <option value="Gas Fitting">Gas Fitting</option>
                  <option value="Drain Laying">Drain Laying</option>
                  <option value="Mixture">Mixture</option>
                </select>
              </dd>
              <dt><label htmlFor="message">Your Message </label></dt>
              <dd>
              <textarea name="message" id="message" rows="5" cols="20"
                        value={this.state.formFields.message}
                        onChange={this.inputChangeHandler}
              />
              </dd>

              <dt></dt>
              <dd><input type="submit" name="submit" id="submit" value="Send"/></dd>
            </dl>
          </form>

          <br className="clear"/>

          <p>{this.state.successMessage}</p>
          <p style={{ color: 'red' }}>{this.state.errorMessage}</p>

          <br className="clear"/>
        </div>
      </Layout>
    )
  }

  inputChangeHandler = (e) => {
    let formFields = { ...this.state.formFields }
    formFields[e.target.name] = e.target.value

    this.setState({
      formFields,
    })
  }

  onFormSubmit = (e) => {
    e.preventDefault()

    let formData = new FormData()

    for (let key in this.state.formFields) {
      if (this.state.formFields.hasOwnProperty(key)) {
        formData.set(key, this.state.formFields[key])
      }
    }

    axios.post('/submit-quote', formData)
      .then((response) => {
        this.setState({
          successMessage: response.data,
          errorMessage: '',
          formFields: {
            name: '',
            email: '',
            phone: '',
            street: '',
            suburb: '',
            city: '',
            dateFrom: '',
            residentialCommercial: 'Residential',
            jobCategory: 'Plumbing',
            message: '',
          },
        })
      })
      .catch((error) => {
        let message = 'An unknown error occurred, please try again.'

        if (error.response && error.response.data) {
          message = error.response.data
        }

        this.setState({
          successMessage: '',
          errorMessage: message,
        })
        console.error(error)
      })
  }
}

export default Quote
